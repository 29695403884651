//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'ModalWrapper',
  computed: {
    ...mapState({
      component: state => state.modal.component,
    }),
  },
};
